<template>
  <div class="index" style="
    background: url('/img/backs-home/fundo-cafe-designer.jpg') no-repeat;
    /* background: url('/img/backs-home/fundo-cafe-dia-3.jpeg'); */
    background-position-x: center;
    background-size: cover;
    ">
    <!-- /* background-size: cover; */ -->
    <div class="index-fundo"></div>
    <c-section class="index__cta">
      <div class="sticky z-20 flex-col px-4" style="display: -webkit-box;display: -ms-flexbox;display: flex;">
        <h1 class="mb-3 text-xl text-center">
          <img :src="logonovo" id="logo" />
        </h1>
        <h3 id="subtitulo" class="mb-3">Bem vindo à nossa casa,<br>agora vamos falar sobre a sua</h3>
        <h3 id="sub-subtitulo" class="mb-3">Escolha a sua região de interesse:</h3>
        <h3 id="indicador-abaixo" class="mb-1">
          <!-- <font-awesome-icon icon="angle-down" /> -->
          <!-- <font-awesome-icon :icon="['fas', 'angles-down']" /> -->
          <svg style="
          fill: white !important;
          width: 40px;
          height: 20px;
          display: inline-flex;
          " xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
            <path d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z"/>
          </svg>
        </h3>
     </div>
      <div class="sticky z-20 flex-col px-4 text-center sistema-de-paginas lg:flex-row lg:text-left">
        <div class="lg:w-2/12"></div>
        <div class="w-full lg:w-4/12">
          <a href="/home">
            <div class="card-pagina santa-maria">
            </div>
            <div class="card-pagina-fotter">
              <div class="sticky z-20 px-4 text-center sistema-de-rodape lg:flex-row lg:text-left">
                <div class="w-1/2 mt-5 mb-5">
                  <h1 class="titulo-card">Santa Maria</h1>
                  <h2 class="subtitulo-card">e Região</h2>
                  <br />
                </div>
                <div class="w-1/2 mt-10">
                  <div class="c-button btn-warning">Acessar Site</div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="w-full lg:w-4/12">
          <a href="/homeSc">
            <div class="card-pagina balneario-camburiu">
            </div>
            <div class="card-pagina-fotter">
              <div class="sticky z-20 px-4 text-center sistema-de-rodape lg:flex-row lg:text-left">
                <div class="w-1/2 mt-5 mb-5">
                  <h1 class="titulo-card">Balneário<br>Camboriú</h1>
                  <h2 class="subtitulo-card">e Região</h2>
                </div>
                <div class="w-1/2 mt-10">
                  <div class="c-button btn-warning">Acessar Site</div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </c-section>
  </div>
</template>
<script>
// import PropertyCTA from "../components/PropertyCTA.vue"
// import FigureCard from "../components/FigureCard.vue"
import CSection from "../components/UI/CSection.vue"
// import MainHeader from "../components/MainHeader.vue"
export default {
  metaInfo: {
    htmlAttrs: {
      lang: "pt-BR"
    },
    title: "CAFÉ INTELIGÊNCIA IMOBILIÁRIA",
    meta: [
      {
        name: "description",
        content:
          "A Café Inteligência Imobiliária atua em Santa Maria/RS e região, na compra e venda de imóveis com foco na  transparência, agilidade e inovação, imóveis santa maria, Imobiliarias santa maria"
      }
    ]
  },
  data() {
    return {
      logonovo: require("../assets/img/logos/3.png")
    }
  },
  components: {
    // MainHeader,
    CSection
    // FigureCard,
    // PropertyCTA
  }
}
</script>
<style lang="postcss">
#logo {
  /* display: block !important; */
  display: inline-block;
  width: 180px;
}
#subtitulo {
  z-index: 2;
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 500;
  /* font-family: "Exo 2" !important; */
  text-align: center;
}
#sub-subtitulo {
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 300;
  /* font-family: "Exo 2"; */
  text-align: center;
}
#indicador-abaixo {
  color: white !important;
  text-align: center;
  font-size: 30px;
}
.sistema-de-rodape {
  display: flex;
  font-weight: bolder;
}
.sistema-de-paginas {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  top: 0;
  .card-pagina {
    /* width: 80%; */
    border: 8px solid #c59459;
    margin: 0 20px;
    height: 200px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom: none;
  }
  .card-pagina-fotter {
    height: auto;
    background-color: #05925b;
    margin: 0 20px;
    border-bottom: none;
    margin-bottom: 20px;
  }
}
.santa-maria {
  /* sss */
  background-image: url('/img/backs-home/santa-maria-2.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
}
.balneario-camburiu {
  background-image: url('/img/backs-home/balneario-camboriu.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
}
.titulo-card {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  filter: drop-shadow(1.763px 2.427px 1.5px rgba(0,0,0,0.51));
}
.subtitulo-card {
  margin-top: 3px;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  filter: drop-shadow(1.763px 2.427px 1.5px rgba(0,0,0,0.51));
  white-space: nowrap;
}
.btn-warning {
  font-size: 15px;
  letter-spacing: -1px;
  line-height: 21px;
  color: #ffffff;
  font-weight: 400;
  filter: drop-shadow(1.763px 2.427px 4px rgba(0,0,0,0.13));
  /* width: 120px !important; */
  height: 47px;
  border-radius: 13px;
  filter: drop-shadow(1.315px 2.696px 3px rgba(0,0,0,0.29));
  background-color: #c59459;
  padding: 10px !important;
}
.btn-warning:hover {
  font-size: 15px;
  letter-spacing: -1px;
  line-height: 21px;
  color: #ffffff;
  font-weight: 400;
  filter: drop-shadow(1.763px 2.427px 4px rgba(0,0,0,0.13));
  /* width: 120px !important; */
  height: 47px;
  border-radius: 13px;
  filter: drop-shadow(1.315px 2.696px 3px rgba(0,0,0,0.29));
  background-color: #c59459;
}
.index-fundo {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.index-fundo::before {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(32, 55, 46, 0.6); /* Cor preta com 80% de opacidade */
  pointer-events: none; /* Garante que a sobreposição não interfere com eventos de mouse */
  z-index: 1;
}
.index__cta {
  z-index: 3;
}
</style>
