<template>
  <header
    class="main-header"
    :class="{ 'main-header--alt': bg }"
    :style="headerStyle"
  >
    <div class="main-header__wrapper container" v-if="$slots.default">
      <slot />
    </div>
  </header>
</template>

<script>
import EventBus from "@/helpers/EventBus"

export default {
  props: {
    bg: {
      type: String,
      default: null
    }
  },
  computed: {
    headerStyle() {
      if (this.bg) {
        return {
          background: `url(${require(`@/assets/img/${this.bg}`)}) no-repeat center/cover`
        }
      }
      return ""
    }
  },
  data() {
    return {
      elementHeight: 0,
      scrollY: 0,
      showSticky: false
    }
  },
  methods: {
    handleScroll() {
      this.scrollY = window.scrollY
      if (this.scrollY > this.elementHeight && !this.showSticky) {
        this.showSticky = true
        EventBus.$emit("header-off-screen", true)
      } else if (this.scrollY < this.elementHeight && this.showSticky) {
        this.showSticky = false
        EventBus.$emit("header-off-screen", false)
      }
    }
  },
  mounted() {
    this.elementHeight = 80
    this.scrollY = window.scrollY
    document.addEventListener("scroll", this.handleScroll)
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll)
  }
}
</script>

<style lang="postcss">
.main-header {
  &__wrapper {
    @apply m-auto relative;
    padding-top: 110px;
    margin-top: -110px;
  }

  &--alt {
    @apply text-white relative;

    &:before {
      content: "";
      background: rgba(0, 0, 0, 0.65);
      @apply absolute top-0 left-0 block w-full h-full;
    }

    ^&__wrapper {
      label {
        color: #fff;
      }
    }
  }
}
.vue-dialog-buttons button:hover {
  color: blue !important;
  font-weight: bolder;
}
.multiselect__content {
  background-color: white !important;
}
.multiselect__content-wrapper {
  background-color: white !important;
  border: none !important;
    width: 100.5% !important;
}
</style>
